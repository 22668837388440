.hero-form-radios .box:last-child {
  margin-bottom: 0;
}

.hero-form-radios .box svg {
  color: #ccd5e1;
  background-color: #ccd5e1;
  border-radius: 50%;
  position: absolute;
  font-size: 20px;
  top: -3px;
  left: -3px;
  height: 20px;
  width: 20px;
}

.hero-form-radios .rb-container {
  position: relative;
}

.hero-form-radios .boxClicked {
  background: #ffffff;
  border: 1px solid #fff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.hero-form-radios .boxClicked svg {
  display: block;
  color: #f9ae3b;
  font-size: 20px;
  background-color: #fff;
  position: absolute;
  top: -3px;
  left: -3px;
  height: 20px;
  width: 20px;
}

.hero-form-radios .boxClicked .boxTitle {
  color: #3f3f3f;
  font-weight: 600;
}

.hero-form-radios .boxClicked .boxContent {
  color: #9b9b9b;
}

.hero-form input::placeholder,
.hero-form input[type='email']::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9c9b9b;
  opacity: 1;
  /* Firefox */
}

.hero-form input[type='email'] {
  color: var(--color-grey-2);
}

.hero-form select {
  color: var(--color-grey-1);
}

.hero-form label {
  display: inline-block;
  width: 50%;
  font-size: 1rem;
  color: var(--color-grey-1);
  font-weight: 400;
}

.hero-section hr {
  margin-top: 2rem;
  border-top: 1px solid var(--color-grey-2);
}

.have-account {
  margin-top: 1.5rem !important;
  text-align: left;
}

.have-account a {
  color: var(--color-light-blue-1);
}

.h2-glossary {
  color: white;
  font-size: 3.1875rem;
  line-height: 3.8125rem;
  font-weight: 300;
  margin-bottom: 15px;
}

.hero-section h1 strong,
.h2-glossary {
  display: block;
  font-weight: 700;
}

.features-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-light-blue-1);
}

.features-section p {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-grey-1);
}

.features-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features-slide-toggles {
  display: flex;
  flex-direction: column;
}

.features-slide-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7rem 2rem;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.5s;
}

.features-slide-toggle p {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0 !important;
  text-align: left !important;
}

.features-slide-toggle p span {
  font-size: 1.375rem;
  font-weight: 700;
  display: block;
  margin-bottom: 1rem;
}

.features-slide-toggle p span,
.features-slide-toggle p {
  color: var(--color-dark-blue-1);
  margin-right: 2rem;
}

.features-slide-arrow {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(../images/home/slider/arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.features-slide-toggle.active {
  opacity: 1;
}

.features-slide-toggle.active>.features-slide-arrow {
  background: url(../images/home/slider/arrow-active.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.features-slide-toggle.active {
  background-color: var(--color-dark-blue-1);
  position: relative;
  cursor: default;
}

.features-slide-toggle.active p span,
.features-slide-toggle.active p {
  color: var(--color-white);
}

.features-slider-slide:not(:first-child) {
  display: none;
}

.features-learn-more>a {
  color: var(--color-dark-blue-1);
  font-weight: 700;
}

.features-learn-more>a>.features-slide-arrow {
  width: 1rem;
  height: 1rem;
  margin-top: -2px;
  vertical-align: middle;
}

.sponsors-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-light-blue-1);
}

.sponsors-section p {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-grey-1);
}

.advertising-partners-and-channels-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-light-blue-1);
}

.memberships-and-publications-section h2 {
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  color: var(--color-white);
}

.sponsor,
.partner-or-channel,
.membership-or-publication {
  height: 100px;
  display: flex;
  align-items: center;
}

.sponsor>img,
.partner-or-channel>img,
.membership-or-publication>img {
  display: block;
  margin: auto;
}

.why-us-section .container {
  padding: 0 10rem;
  display: flex;
  justify-content: space-between;
}

.why-us-text {
  width: 40%;
}

.why-us-text h2 {
  color: var(--color-dark-blue-1);
  font-size: 2rem;
  font-weight: 700;
}

.why-us-text p {
  margin-top: 1.5rem;
  color: var(--color-grey-1);
  font-size: 1.125rem;
  font-weight: 400;
}

.why-us-characteristic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.why-us-characteristic-image {
  padding: 1rem;
  box-shadow: 0px 16px 40px rgba(11, 90, 157, 0.13);
  border-radius: 16px;
}

.why-us-characteristic-text {
  margin-left: 1.5rem;
}

.why-us-characteristic h3 {
  color: var(--color-grey-1);
  font-size: 1.25rem;
  font-weight: 700;
  /* white-space: nowrap; */
}

.why-us-characteristic p {
  margin-top: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: var(--color-grey-1);
  font-size: 1.125rem;
  font-weight: 400;
}

.why-us-section>img {
  float: left;
  margin-top: -7rem;
}

.promo-video-section {
  margin-top: 10rem;
  padding: 0 10rem;
}

.promo-video-section .container {
  margin-right: 8rem;
  background: linear-gradient(265.74deg, #f9ae3b -0.24%, #ff6a40 102.38%);
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-video-text {
  padding: 4rem 0 4rem 5rem;
  width: 50%;
}

.promo-video-text h2 {
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 700;
}

.promo-video-text p {
  margin-top: 1.5rem;
  color: var(--color-white);
  width: 71%;
  font-size: 1rem;
  font-weight: 400;
}

.promo-video-text a {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 1rem 3rem;
  background-color: var(--color-white);
  color: var(--color-orange-1);
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 8px;
}

.advertising-platform-index .advertising-platform-banner {
  min-height: 600px;
  background-image: url(../images/advertisers-index-thmb.png) !important;
}

.blog-posts .blog-post-data h3 {
  margin-top: 40px;
}

@media (max-width: 959px) {
  .blog-posts .blog-post-data h3 {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
    width: 0px !important;
    background-color: #f5f5f5;
  }

  start-section h2,
  start-section p {
    text-align: center !important;
  }

  .features-slide-toggle {
    width: 50.4vw !important;
    box-sizing: border-box !important;
  }

  .features-slide-toggle p span {
    line-height: 22px;
  }

  .features-slider-slides {
    margin: 0;
  }

  .features-slider-slides .features-slider-slide {
    padding: 0 !important;
  }

  .advertising-section p {
    padding: 0 30px;
  }

  .advertising-section a {
    width: 90%;
  }

  .advertising-section .advertising-proven-solution {
    font-size: 12px !important;
  }

  .blog-posts article.blog-post:first-child {
    width: 100%;
    height: auto;
  }

  .blog-part-section .container {
    padding: 0 20px !important;
  }

  .blog-part-section h2,
  .blog-part-section h2~p {
    padding: 0 33px;
  }

  .blog-posts {
    display: flex;
    flex-wrap: wrap;
  }

  .blog-posts article.blog-post {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .blog-posts article.blog-post:nth-last-child(1),
  .blog-posts article.blog-post:nth-last-child(2) {
    margin-bottom: 0;
  }

  .blog-posts .blog-post-data h3 {
    font-size: 18px;
    font-weight: 700;
  }

  .blog-posts .blog-post-data p {
    font-size: 14px;
    line-height: 17px;
  }

  .blog-post-data a:nth-last-child(1) {
    font-size: 16px;
  }

  section.blog-part-section {
    margin-bottom: 0;
  }
}

@media (max-width: 413px) {
  .blog-posts article.blog-post {
    width: 100%;
  }

  .blog-posts article.blog-post:nth-last-child(1) {
    margin-bottom: 0;
  }

  .blog-posts article.blog-post:nth-last-child(2) {
    margin-bottom: 60px;
  }
}

.publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
  width: 0px !important;
  background-color: #f5f5f5;
}

.advertising-platform-index {
  margin-bottom: 0 !important;
}

@media screen and (min-width: 568px) and (max-width: 767px) {

  start-section h2,
  start-section p {
    text-align: center !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
    width: 0px !important;
    background-color: #f5f5f5;
  }

  /* .sponsors-list li.sponsor.sponsors-list__item {
    width: 28% !important;
  } */
  start-section h2 {
    font-size: 31px;
    line-height: 32px;
  }

  start-section h2,
  start-section p {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
    width: 0px !important;
    background-color: #f5f5f5;
  }

  .publisher-app-section .container .dashboard-pub {
    width: 260px !important;
  }

  .promo-video-text {
    padding: 50px 34px 70px 4vw !important;
  }

  /* .sponsors-list li.sponsor.sponsors-list__item {
    width: 22% !important;
  } */
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
    width: 0px !important;
    background-color: #f5f5f5;
  }
}

/* NEW */

body {
  overflow-x: hidden;
}

.hero-section {
  background: url(../images/hero-background.svg);
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.hero-section .container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 190px 160px 70px 160px;
}

.hero-text {
  margin-top: 70px;
  max-width: 570px;
  margin-right: 100px;
  color: #ffffff;
}

.hero-text .call-action {
  margin-top: 0;
  margin-right: 34px;
}

.hero-section__buttons {
  margin-top: 50px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 25px;
}

@media (max-width: 1199px) {
  .hero-section__buttons {
    padding-bottom: 0;
  }
}

.hero-text h1 {
  margin-bottom: 15px;
  font-size: 46px;
  line-height: 56px;
  font-weight: 700;
}

.hero-text p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.hero-text .call-action {
  min-width: 230px;
  padding: 19px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  border-radius: 8px;
  color: #ffffff;
  background-color: #f9ae3b;
}

@media (max-width: 767px) {
  .hero-text .call-action {
    margin-right: 20px;
    padding: 14px 18px;
    font-size: 16px;
    line-height: 20px;
    min-width: auto;
    width: 100%;
    max-width: 288px;
  }
}

.hero-form {
  max-width: 462px;
  padding: 12px;
  border-radius: 16px;
  background-color: #ffffff27;
}

.hero-form form {
  border-radius: 16px;
  padding: 48px 34px 30px;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
}

.hero-form h3 {
  margin: 0 0 10px;
  color: #012d6c;
  font-size: 20px;
  line-height: 24px;
}

.hero-form .hero-form__lead {
  margin: 0 0 24px;
  font-size: 16px;
  line-height: 22px;
  color: #9b9b9b;
}

.hero-form-radios {
  margin-bottom: 16px;
}

.hero-form-radios .boxes {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.hero-form-radios .box {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.hero-form__field {
  margin-bottom: 16px;
  width: 100%;
  padding: 18px 24px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  border: 1px solid #ececec;
  background-color: var(--color-white);
  border-radius: 8px;
}

.hero-form button {
  /* margin: 0 0 45px 0; */
  display: inline-block;
  width: 100%;
  padding: 20px 40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background: #ffa443;
  border-radius: 15px;
}

.hero-form .have-account {
  margin: 0;
  padding-top: 34px;
  border-top: 1px solid #ececec;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.hero-form .have-account a {
  text-decoration: underline;
}

.hero-form-radios .boxClicked .boxTitle {
  font-weight: 700;
}

.hero-form-radios .flex-center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hero-form-radios .boxTitle {
  margin-left: 8px;
  color: #6681a6;
  /* font-family: "Montserrat"; */
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.hero-form-radios .box::before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #66a9d7;
}

.hero-form-radios .box.boxClicked::before {
  background-color: #ffffff;
  background-image: url(../images/home/check.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1199px) {
  .hero-form {
    display: none;
  }
}

.hero-section__video {
  display: flex;
  align-items: center;
}

.hero-text .play-video-button {
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 11px;
  flex-shrink: 0;
}

.play-video-button__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135.55deg,
      #ffffff 14.57%,
      rgba(255, 255, 255, 0) 102.08%);
  opacity: 0.2;
}

.play-btn-div {
  display: flex;
  align-items: center;
}

.play-btn-div span {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.modal-video-movie-wrap,
.modal-video-movie-wrap iframe {
  border-radius: 20px;
}

.modal-video-close-btn {
  height: 48px;
  right: -10%;
  border-radius: 50%;
  background-color: white;
  width: 48px;
  background: url(../images/icon-close-24.svg) white 50% no-repeat;
}

.modal-video-close-btn::after,
.modal-video-close-btn::before {
  content: none;
}

.play-video-button__inner {
  margin: auto;
  width: 36px;
  height: 36px;
  background-color: var(--color-yellow-1);
  background-image: url(../images/play.svg);
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.hero-section .label {
  margin-top: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

@media (max-width: 1439px) {
  .hero-section .container {
    padding: 190px 11.11vw 70px 11.11vw;
  }
}

@media (max-width: 1199px) {
  .hero-section {
    padding: 0;
    background-size: cover;
    background-position: bottom left;
  }

  .hero-section .container {
    padding: 98px 11.11vw 130px;
  }

  .hero-text {
    margin: 0 auto 0 0;
  }
}

@media (max-width: 767px) {
  .hero-section .container {
    padding: 98px 24px 130px;
  }

  .hero-text h1 {
    font-size: clamp(32px, 8vw, 40px);
    line-height: clamp(41px, 10vw, 49px);
  }

  .hero-text p {
    font-size: 16px;
    line-height: 22px;
  }

  .hero-text {
    margin-right: 0;
  }

  .modal-video-close-btn {
    right: 0;
    top: -60px;
  }
}

@media (max-width: 599px) {
  .hero-section__buttons a {
    flex-grow: 1;
    max-width: 288px;
    text-align: center;
    margin-right: 20px;
  }

  .hero-section .label {
    display: none;
  }

  .hero-section .play-video-button {
    margin-right: 0;
  }

  .play-btn-div span {
    display: none;
  }
}

@media (max-width: 359px) {
  .hero-text h1 {
    font-size: clamp(24px, 8vw, 32px);
    line-height: clamp(31px, 8vw, 39px);
  }
}

/* features-section */

.features-section {
  padding: 0 160px;
}

.features-section>img {
  position: absolute;
  left: 0;
  width: 120px;
  float: none;
}

@media (max-width: 1439px) {
  .features-section>img {
    width: 8.33vw;
  }

  .features-section {
    padding: 0 11.11vw;
  }
}

.features-section .container {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0;
  max-width: 910px;
  width: 100%;
}

.features-section__lead-text h2 {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 24px;
}

.features-section__lead-text p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .features-section__lead-text p {
    font-size: 16px;
    line-height: 22px;
  }
}

.features-section__lead-text {
  margin-bottom: 45px;
}

.features-slider {
  margin-top: 0;
}

.features-slider-slides {
  margin-left: 30px;
}

@media (max-width: 845px) {
  .features-section {
    padding: 0 24px;
  }

  .features-section>img {
    display: none;
  }

  .features-section__lead-text {
    padding: 0 24px;
    margin: 0 auto 50px;
  }

  .features-slider {
    flex-direction: column;
  }

  .features-slide-toggles {
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
  }

  .features-slide-toggles::-webkit-scrollbar {
    display: none;
  }

  .features-slide-toggles {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .features-slide-toggles__inner {
    display: flex;
    flex-direction: row;
  }

  .features-slide-toggle {
    box-sizing: border-box;
    flex-shrink: 0;
    margin: 6px;
    min-width: 104px;
    padding: 15px 16px 19px 16px;
    width: 38.4vw;
    background: #f8f8f8;
  }

  .features-slide-text h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }

  .features-slide-text p {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
    margin-right: 0;
    word-wrap: break-word;
  }

  .features-slider-slides {
    display: block;
    width: 100%;
  }

  .features-slider-slide {
    padding: 0 24px;
  }

  .features-slider-slide img {
    margin: 3rem auto 0;
    display: block;
    max-width: 360px;
    width: 100%;
    height: auto;
  }

  .features-slider-lottie-player {
    margin: 3rem auto 0;
    display: block;
    max-width: 360px;
    width: 100%;
    height: auto;
    float: none;
  }

  .features-slide-toggles .features-slide-arrow {
    display: none;
  }

  .features-slide-toggle.active {
    left: 0;
  }

  .features-slider-slide img {
    float: none;
  }

  .features-section .features-learn-more {
    margin-top: 80px;
    font-size: 18px;
    line-height: 22px;
  }
}

/* sponsors-section */

.sponsors-section {
  position: relative;
}

.sponsors-section>img {
  position: absolute;
  top: 55px;
  right: 0;
  width: 8.33vw;
  max-width: 120px;
  float: none;
  margin-top: 0;
  height: auto;
}

.sponsors-section h2 {
  font-size: 32px;
  line-height: 39px;
}

.sponsor {
  height: auto;
}

.sponsors-section p {
  margin-top: 24px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .sponsors-section h2 {
    margin: 0 auto;
  }

  .sponsors-section p {
    margin-top: 16px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;
  }

  .sponsors-section>img {
    display: none;
  }
}

/* advertising-partners-and-channels-section */

.advertising-partners-and-channels-section {
  position: relative;
  padding: 80px 0 70px;
}

.advertising-partners-and-channels-section h2 {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 50px;
}

.partner-or-channel {
  height: auto;
}

@media (max-width: 767px) {
  .advertising-partners-and-channels-section {
    padding: 80px 0 120px;
  }
}

/* memberships-and-publications-section */

.memberships-and-publications-section {
  color: #ffffff;
  margin-top: -100px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../images/wave-background.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: unset;
}

@media (min-width: 1441px) {
  .memberships-and-publications-section {
    background-size: cover;
    margin-top: -20px;
    margin-bottom: 60px;
  }
}

.memberships-and-publications-section .container {
  display: flex;
  flex-direction: column;
  padding: 220px 24px 220px 24px;
}

@media (max-width: 767px) {
  .memberships-and-publications-section {
    margin-bottom: 80px;
  }

  .memberships-and-publications-section .container {
    padding: 270px 24px 220px 24px;
  }
}

.memberships-and-publications-section h2 {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 50px;
}

.membership-or-publication {
  height: auto;
}

@media (max-width: 767px) {
  .memberships-and-publications-section h2 {
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

.why-us-section {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.why-us-section__inner {
  display: flex;
  min-width: 200px;
}

.why-us-characteristics {
  margin-left: 32px;
}

.owl-stage-outer {
  overflow: visible !important;
}

.owl-dots {
  margin-top: 1rem;
  text-align: center;
}

.owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  background: #f0f0f0;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  transform: none;
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #f9ae3b;
  transform: none;
}

@media (min-width: 768px) {
  .owl-dots.disabled {
    display: none;
  }
}

.why-us-characteristic {
  display: flex;
  min-height: 150px;
}

.why-us-section>img {
  position: absolute;
  bottom: 13px;
  left: 0;
  width: 8.33vw;
  max-width: 120px;
  float: none;
  margin-top: 0;
  height: auto;
}

.why-us-text {
  width: 100%;
  max-width: 390px;
}

.why-us-text h2 {
  margin: 0 0 24px 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #012d6c;
}

.why-us-characteristic h3 {
  margin: 0 0 10px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3f3f3f;
}

.why-us-characteristic-text {
  margin-left: 24px;
}

.why-us-text p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: #3f3f3f;
}

.why-us-section .container {
  padding: 0 11.11vw;
}

.why-us-characteristic-image {
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 16px 40px rgb(11 90 157 / 13%);
  display: flex;
  flex-shrink: 0;
  height: 82px;
  justify-content: center;
  width: 82px;
}

.why-us-characteristic-image img {
  width: auto !important;
}

@media (min-width: 1440px) {
  .why-us-section .container {
    padding: 0 160px;
  }
}

@media (max-width: 959px) {
  .why-us-section__inner {
    flex-direction: column;
  }

  .why-us-section .container {
    flex-direction: column;
  }

  .why-us-text {
    width: auto;
    max-width: none;
    text-align: center;
  }

  .why-us-text h2 {
    padding: 0;
  }

  .why-us-characteristics {
    margin: 45px 0 0 0;
  }
}

@media (max-width: 767px) {
  .why-us-section>img {
    display: none;
  }

  .why-us-characteristic {
    justify-content: flex-start;
  }
}

/* promo-video-section */

.promo-video-section {
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
}

.promo-video-section {
  padding: 0 160px;
}

@media (max-width: 1439px) {
  .promo-video-section {
    padding: 0 11.11vw;
  }
}

@media (max-width: 767px) {
  .promo-video-section {
    padding: 0 24px;
  }
}

.promo-video-section__inner {
  position: relative;
}

/* @media (min-width: 1440px) {
  .promo-video-section__inner {
    padding-right: 143px;
  }
} */

.promo-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/about-us/hero-video-preview.jpg);
  width: 50%;
  padding-bottom: 28.125%;
  background-size: cover;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.4);
  border-radius: 21px;
}

.promo-video-play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.promo-video-play-button .play-video-button__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 120px;
  opacity: 0.2;
}

.promo-video-play-button,
.promo-video-play-button .play-video-button__bg {
  width: 88px;
  height: 88px;
}

.promo-video-play-button .play-video-button__inner {
  width: 60px;
  height: 60px;
  background-size: 21px 21px;
  background-color: var(--color-orange-1);
}

.promo-video-section .container {
  margin-right: 0;
  padding: 0;
}

.promo-video-text {
  flex-shrink: 2;
  padding: 50px 0 70px 5vw;
  /* width: 55%; */
  max-width: 540px;
  width: 100%;
}

@media (min-width: 1440px) {
  .promo-video-text {
    padding-left: 70px;
  }
}

.promo-video-text h2 {
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 39px;
}

.promo-video-text p {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 20px 0;
}

@media (min-width: 960px) {
  .promo-video {
    margin-right: -32px;
  }
}

.promo-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.promo-video__inner {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
}

@media (max-width: 959px) {
  .promo-video-section {
    margin-bottom: calc(200px - (959px - 100vw) / 5);
  }

  .promo-video-section .container {
    position: relative;
    flex-direction: column;
    padding: 52px 22px 28vw;
  }

  .promo-video {
    position: absolute;
    width: calc(100% - 48px);
    padding-bottom: calc(56.25% - 27px);
    bottom: 0;
    transform: translateY(-50%);
  }

  .promo-video-section__inner {
    padding-right: 0;
  }

  .promo-video-text {
    padding: 0 20px;
  }

  .promo-video-text p {
    font-size: 16px;
    line-height: 22px;
  }

  .promo-video-section a {
    display: none;
  }

  .promo-video {
    /* width: 100%; */
    margin-top: calc((-100vw + 48px) / 4.5);
    margin-right: 0;
    transform: translateY(50%);
  }
}

/* start growing */

start-section {
  text-align: center;
  color: #ffffff;
  padding: 125px 25px 55vw;
  background-image: url(../images/start-growing-bg-4.svg),
    url(../images/start-growing-bg-2.svg), url(../images/start-growing-bg-1.svg);
  background-position: bottom -5vw center, bottom center, top center;
  background-size: 160vw auto, 100vw auto, cover;
  background-repeat: no-repeat;
}

start-section h2 {
  margin: 0 auto;
  max-width: 900px;
  font-size: 60px;
  line-height: 73px;
  margin-bottom: 24px;
  font-weight: 700;
}

start-section p {
  margin: 0 auto;
  max-width: 900px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 36px;
}

start-section__button {
  display: inline-block;
  margin: 0 auto 40px;
  padding: 18px 60px;
  font-size: 18px;
  line-height: 22px;
  background-color: #f9ae3b;
  border: 1px solid #f9ae3b;
  color: #ffffff;
  border-radius: 15px;
  font-weight: 700;
}

.wave-mobile {
  display: none;
}

@media (max-width: 767px) {
  start-section {
    padding: 140px 25px 55vw;
    background-image: url(../images/wave-mobile.png),
      url(../images/start-growing-bg-4.svg),
      url(../images/start-growing-bg-2.svg),
      url(../images/start-growing-bg-1.svg);
    background-size: 100% 140px, 160vw auto, 100vw auto, cover;
    background-position: top 0 center, bottom -5vw center, bottom -10px center,
      top center;
  }

  start-section__phone-bg {
    display: none;
  }

  start-section h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: left;
    margin-bottom: 16px;
  }

  start-section p {
    margin-bottom: 36px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  }

  start-section__button {
    padding: 20px 30px;
    width: 100%;
    max-width: 288px;
  }
}

.sponsors-section-grid,
.advertising-partners-and-channels-section-grid {
  max-width: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.sponsors-list,
.partners-and-channels-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.memberships-and-publications-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsors-list li,
.partners-and-channels-list li,
.memberships-and-publications-list li {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {
  .memberships-and-publications-list {
    display: grid;
    grid-template-columns: repeat(3, 220px);
    gap: calc(.5 * 200px);
    overflow: hidden;
  }

  .memberships-and-publications-list>li {
    display: inline-block;
    grid-area: 1/1;
    width: 100%;
    object-fit: cover;
    animation: r 10s linear infinite;
  }

  .memberships-and-publications-list>li:nth-child(2) {
    animation-delay: calc((1 - 2) / 5 * 10s);
  }

  .memberships-and-publications-list>li:nth-child(3) {
    animation-delay: calc((1 - 3) / 5 * 10s);
  }

  .memberships-and-publications-list>li:nth-child(4) {
    animation-delay: calc((1 - 4) / 5 * 10s);
  }

  .memberships-and-publications-list>li:nth-child(5) {
    animation-delay: calc((1 - 5) / 5 * 10s);
  }

  @keyframes r {
    16.666666666666668% {
      transform: translate(-100%);
    }

    16.766666666666666% {
      transform: translate(400%);
    }
  }
}

@keyframes r {
  10% {
    transform: translate(-100%);
  }

  10.01% {
    transform: translate(300%);
  }
}

.sponsors-list .sponsors-list__item--jax picture {
  width: 35px;
  height: auto;
  max-width: 50%;
}

.sponsors-list .sponsors-list__item--cyberghost picture {
  width: 106px;
  height: auto;
  max-width: 100%;
}

.sponsors-list .sponsors-list__item--hotvpn picture {
  width: 83px;
  height: auto;
  max-width: 82.5%;
}

.sponsors-list .sponsors-list__item--fluent picture {
  width: 100px;
  height: auto;
  max-width: 82.5%;
}

.sponsors-list .sponsors-list__item--jax picture img,
.sponsors-list .sponsors-list__item--cyberghost picture img,
.sponsors-list .sponsors-list__item--hotvpn picture img,
.sponsors-list .sponsors-list__item--fluent picture img {
  width: 100%;
  height: 100%;
}

.sponsors-list img,
.partners-and-channels-list img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 960px) {

  .sponsors-list li,
  .partners-and-channels-list li,
  .memberships-and-publications-list li {
    width: 159px;
    height: 101px;
  }
}

@media (max-width: 959px) {

  .memberships-and-publications-section {
    background-size: cover;
  }

  .sponsors-list li,
  .partners-and-channels-list li {
    width: 33%;
  }
}

@media (max-width: 350px) {
  .memberships-and-publications-section {
    background-position: center;
  }

  .advertisers .sponsors-list li,
  .partners-and-channels-list li {
    width: 50%;
  }

  .sponsors-list li,
  .partners-and-channels-list li {
    width: 50%;
  }
}

.search-engines-section .sponsors-list {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.search-engines-section .sponsors-list li {
  margin: 0;
  padding: 0 30px;
}

@media (max-width: 959px) {
  .search-engines-section .sponsors-list {
    flex-wrap: wrap;
  }

  .search-engines-section .sponsors-list li {
    width: 33.33%;
  }

  .sponsors-list img,
  .partners-and-channels-list img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .search-engines-section .sponsors-list {
    flex-direction: column;
  }

  .search-engines-section .sponsors-list li {
    margin: 0 auto 60px auto;
  }

  .search-engines-section .sponsors-list li:nth-last-child(1) {
    margin: 0 auto;
  }
}

.features-slider-lottie-player {
  padding: 1.5rem !important;
}

.features-slider-slide p {
  margin: -1rem !important;
}